import React, { useState, useEffect } from "react";
import "./dashboard.css";


import Union from "../../../../../src/assets/images/Union.svg";
import Vector from "../../../../../src/assets/images/Vector.svg";
import Offline from "../../../../../src/assets/images/Offline.svg";
import Upcoming from "../../../../../src/assets/images/Online.svg";

import { Link, ApiUrl, Axios, Row, Col } from "../../../../components";

const { API_GET_ALL_TOTAL_COLLECTION_CASES } = ApiUrl;

const Dashboard = () => {
  const [list, setList] = useState([]);

  const getTableRecords = async (order, orderBy) => {
    Axios.get(API_GET_ALL_TOTAL_COLLECTION_CASES)   
      .then((res) => {
        const list = res?.data;
        setList(list?.data);
      })
      .catch((err) => {
        console.log("");
      });
  };
  useEffect(() => {
    getTableRecords();
  }, []);
  return (
    <div>
      <section className="content-header">
        <h1>Dashboard</h1>
      </section>

      <section className="content">
        <div className="row" bis_skin_checked={1}>
          <div className="col-md-12" bis_skin_checked={1}>
            <div className="nav-tabs-custom" bis_skin_checked={1}>
              <ul className="nav nav-tabs">
                <li className="active">
                  <Link to="/app/party/register-case">Arbitration</Link>
                </li>
                <li className="mediation">
                  <Link to="/app/client/mediation/filed-cases">Mediation</Link>
                </li>
                <li className="mediation">
                  <Link to="/app/party/conciliation">Conciliation</Link>
                </li>
                <li className="mediation">
                  <Link to="/app/party/bookings">Booking</Link>
                </li>
              </ul>
              <div className="tab-content mt-5" bis_skin_checked={1}>
                <div
                  className="tab-pane active"
                  id="booking_tab"
                  bis_skin_checked={1}
                >
                  <div className="row" bis_skin_checked={1}>
                    <div className="col-lg-3 col-xs-6" bis_skin_checked={1}>
                      <div className="small-box bg-aqua" bis_skin_checked={1}>
                        <div className="inner" bis_skin_checked={1}>
                          <h3>{list?.freshCases || 0}</h3>
                          <h5>Fresh Cases</h5>
                        </div>
                        <div className="icon" bis_skin_checked={1}>
                          <img src={Union} alt="Union" />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-xs-6" bis_skin_checked={1}>
                      <div className="small-box bg-aqua" bis_skin_checked={1}>
                        <div className="inner" bis_skin_checked={1}>
                          <h3>
                            {list?.pendingCases || 0}{" "}
                            <sup style={{ fontSize: 20 }} />
                          </h3>
                          <h5>Pending Cases</h5>
                        </div>
                        <div className="icon" bis_skin_checked={1}>
                          <img src={Vector} alt="Vector" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-xs-6" bis_skin_checked={1}>
                      <div className="small-box bg-aqua" bis_skin_checked={1}>
                        <div className="inner" bis_skin_checked={1}>
                          <h3>{list?.disposedCases || 0}</h3>
                          <h5>Disposed Cases</h5>
                        </div>
                        <div className="icon" bis_skin_checked={1}>
                          <img src={Offline} alt="Union" />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-xs-6" bis_skin_checked={1}>
                      <div className="small-box bg-aqua" bis_skin_checked={1}>
                        <div className="inner" bis_skin_checked={1}>
                          <h3>{list?.totalCases || 0}</h3>
                          <h5>Total Cases</h5>
                        </div>
                        <div className="icon" bis_skin_checked={1}>
                          <img src={Upcoming} alt="Union" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <Row>
                  <Col xs={12} md={6}>
                    <div className="box box-warning mt-5">
                      <div className="box-header d-flex">
                        <i className="zmdi zmdi-calendar-check icns-notication mt-3" />
                        <h6 className="box-title mx-2 mt-3 arbitration-cases">
                          <b>Arbitration Cases Notification</b>
                        </h6>

                        <div>
                          <button className="mx-5 view-all-btn">view all</button>
                        </div>

                      </div>
                      <div className="box-body">
                        <ul className="todo-list">
                          <li>
                            <span className="text">20 may 2009 ARB.000666/IDRC</span>
                            <div className="tools">
                              view <i className="fa fa-eye" />
                            </div>
                          </li>
                          <li>
                            <span className="text">20 may 2009 ARB.000666/IDRC</span>
                            <div className="tools">
                              view <i className="fa fa-eye" />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="box box-warning mt-5">
                      <div className="box-header d-flex">
                        <i className="zmdi zmdi-calendar-check icns-notication mt-3" />
                        <h6 className="box-title mx-2 mt-3 arbitration-cases">
                          <b>Upcoming Hearings Data</b>
                        </h6>
                        <button className="mx-5 view-all-btn">view all</button>
                      </div>
                      <div className="box-body">
                        <ul className="todo-list">
                          <li>
                            <span className="text">20 may 2009 ARB.000666/IDRC</span>
                            <div className="tools">
                              view <i className="fa fa-eye" />
                            </div>
                          </li>
                          <li>
                            <span className="text">20 may 2009 ARB.000666/IDRC</span>
                            <div className="tools">
                              view <i className="fa fa-eye" />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </div>
  );
};

export default Dashboard;
