import React, { Component, Fragment } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import "../assets/vendors/style";
import indigoTheme from "./themes/indigoTheme";
import cyanTheme from "./themes/cyanTheme";
import orangeTheme from "./themes/orangeTheme";
import amberTheme from "./themes/amberTheme";
import pinkTheme from "./themes/pinkTheme";
import blueTheme from "./themes/blueTheme";
import purpleTheme from "./themes/purpleTheme";
import greenTheme from "./themes/greenTheme";
import darkTheme from "./themes/darkTheme";
// import AppLocale from "../lngProvider1";
import Landingpage from "../components/Common/main-dashboard/main"
import Aboutus from "../components/Common/main-dashboard/section-about";
import Services from "../components/Common/main-dashboard/services";
import Privacy from "../components/Common/main-dashboard/privacy-policy";

import TruceCall from "../components/Common/main-dashboard/Trucecall";

import IndiaPost from "../app/routes/Indiapost"

import LoginChecker from "../util/LoginChecker";
import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK,
} from "../constants/ThemeColors";

import MainApp from "../app/index";
import SignIn from "./SignIn";
import { setInitUrl } from "../actions/Auth";
import RTL from "../util/RTL";
import asyncComponent from "../util/asyncComponent";
import SignUpParty from "./SignUp-Party";
import SignUpMediator from "./SignUp-Mediator";
import SignUpAdvocate from "./SignUp-Advocate";
import SignUpArbitrator from "./SignUp-Arbitrator"
import ForgetPassword from "./Forget-password";
import Agreement from "../components/Common/Agreement";
import Partysignin from "./Party-SignIn"


const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />

      ) : (

        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {

  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }

  getColorTheme(themeColor, applyTheme) {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case DARK_CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      default:
        createMuiTheme(indigoTheme);
    }
    return applyTheme;
  }

  render() {
    const {
      match,
      location,
      themeColor,
      isDarkTheme,
      locale,
      authUser,
      initURL,
      isDirectionRTL,
    } = this.props;
    const role = localStorage.getItem("role");
    let applyTheme = createMuiTheme(indigoTheme);
    if (isDarkTheme) {
      document.body.classList.add("dark-theme");
      applyTheme = createMuiTheme(darkTheme);
    } else {
      applyTheme = this.getColorTheme(themeColor, applyTheme);
    }

    // console.log(authUser, initURL, "<=-===authUser");
    if (location.pathname === "/") {
      if (authUser === null) {
        return <Redirect to={"/home"} />;
      } else if (initURL === "" || initURL === "/" || initURL === "/signin") {
        return <Redirect to={"/app/dashboard/index"} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }
    if (isDirectionRTL) {
      applyTheme.direction = "rtl";
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
      applyTheme.direction = "ltr";
    }

    // const currentAppLocale = AppLocale[locale.locale];

    return (
      <Fragment>

        {/* <LoginChecker /> */}
        <MuiThemeProvider theme={applyTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <IntlProvider
            // locale={currentAppLocale.locale}
            // messages={currentAppLocale.messages}
            >
              <RTL>
                <div className="app-main">
                  <Switch>
                    <RestrictedRoute
                      path={`${match.url}app`}
                      authUser={authUser}
                      component={MainApp}
                    />
                    <Route path="/signin" component={SignIn} />
                    <Route path="/party-signup" component={SignUpParty} />
                    <Route path="/mediator-signup" component={SignUpMediator} />
                    <Route path="/advocate-signup" component={SignUpAdvocate} />
                    <Route path="/arbitrator-signup" component={SignUpArbitrator} />
                    <Route path="/home" component={Landingpage} />
                    <Route path="/aboutus" component={Aboutus} />
                    <Route path="/services" component={Services} />
                    <Route path="/forget-password" component={ForgetPassword} />
                    <Route path="/mediation-agreement" component={Agreement} />
                    <Route path="/party-signin" component={Partysignin} />
                    <Route path="/privacy" component={Privacy} />
                    <Route path="/truce-talk" component={TruceCall} />

                    <Route path="/Indiapost" component={IndiaPost} />


                    <Route
                      component={asyncComponent(() =>
                        import("../app/routes/extraPages/routes/404")
                      )}
                    />
                  </Switch>
                </div>
              </RTL>
            </IntlProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>

      </Fragment>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const {
    themeColor,
    sideNavColor,
    darkTheme,
    locale,
    isDirectionRTL,
  } = settings;
  const { authUser, initURL } = auth;
  return {
    themeColor,
    sideNavColor,
    isDarkTheme: darkTheme,
    locale,
    isDirectionRTL,
    authUser,
    initURL,
  };
};

export default withRouter(connect(mapStateToProps, { setInitUrl })(App));
