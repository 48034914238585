import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { userSignOut } from "../../actions/Auth";
import IntlMessages from "../../util/IntlMessages";
import Chip from "@material-ui/core/Chip";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import Notifications from "./notification";

import { ApiUrl, Axios, Link } from "../../components";
const { API_GET_NOTIFICATION_COUNT } = ApiUrl;

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false,
    isToggled: false,
    lawMediation: true,
    notif: false,
    count: "",
  };

  getTableRecords = async () => {
    Axios.Get(API_GET_NOTIFICATION_COUNT)
      .then((res) => {
        const list = res.data;
        this.setState({ count: list?.data?.length });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  componentDidMount = () => {
    this.getTableRecords();
  };
  toggel = () => {
    this.openNotification();
  };
  handleChange = () => {
    localStorage.setItem("lawMediation", this.state.lawMediation);
    console.log(
      localStorage.setItem("lawMediation", this.state.lawMediation),
      this.state.lawMediation,
      "this.state.lawMediation"
    );
    this.setState({
      isToggled: !this.state.isToggled,
      lawMediation: !this.state.lawMediation,
    });
  };

  handleClick = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  refresh = () => {
    window.location.reload();
  };

  openNotification = () => {
    this.setState({ notif: !this.state.notif });
  };

  render() {
    const role = localStorage.getItem("role");
    const userName = localStorage.getItem("name");
    const firstLetter = userName?.charAt(0);
    const lawMediation = localStorage.getItem("lawMediation");
    const lastIndex = window.location.hash.lastIndexOf("/");
    const extractedString = window.location.hash.substring(lastIndex + 1);

    // console.log(
    //   lawMediation,
    //   this.state.lawMediation,
    //   firstLetter,
    //   "extractedString"
    // );
    return (
      <>
        <div className="user-profile d-flex flex-row align-items-center">
          {role == "LawFirm" && (
            <Link
              to={`${
                // !this.state.isToggled
                lawMediation === "true"
                  ? "/app/mediation/dashboard/"
                  : "/app/lawfirm/dashboard/index"
              }`}
              onClick={this.handleChange}
              className="switch mr-4"
            >
              <label class="switch">
                <input type="checkbox" />
                <span
                  className={
                    // extractedString === "mediation"
                    lawMediation === "false"
                      ? "slider round toggle-slide"
                      : "slider round"
                  }
                ></span>
              </label>
            </Link>
          )}

          <span className="header-refresh" onClick={this.refresh}>
            <i class="zmdi zmdi-refresh-alt"></i>
          </span>
          {(role === "LawFirm" || role === "Mediator" || role === "casemanager") && (
            <span className="header-refresh" onClick={this.openNotification}>
              <i className="zmdi zmdi-notifications-none"></i>
              {this.state.count > 0 && (
                <span className="approval-notif-count">{this.state.count}</span>
              )}
            </span>
          )}
          <div className="user-detail desk-view" onClick={this.handleClick}>
            <Chip
              icon={<AccountCircleIcon />}
              label={localStorage.getItem("name")}
            />
            <i class="zmdi zmdi-caret-down zmdi-hc-fw align-middle"></i>
          </div>

          <div className="mob-view first-l-box" onClick={this.handleClick}>
            <span>{firstLetter}</span>
          </div>
          <Menu
            className="user-info"
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.handleRequestClose}
            PaperProps={{
              style: {
                minWidth: 120,
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}
          >
            {/* <MenuItem
            onClick={() => {
              this.handleRequestClose();
            }}
          >
            <NavLink to="/app/settings/password-change">
              <i class="zmdi zmdi-key zmdi-hc-fw mr-2"></i>
              <IntlMessages id="Password" />
            </NavLink>
          </MenuItem> */}
            {/* <MenuItem
              onClick={() => {
                this.handleRequestClose();
                this.props.userSignOut();
              }}
              className="mob-view"
            >
            <AccountCircleIcon />
             {userName}
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                this.handleRequestClose();
                this.props.userSignOut();
              }}
            >
              <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

              <IntlMessages id="Logout" />
            </MenuItem>
          </Menu>
        </div>
        {this.state.notif === true && (
          <div className="notif-pop-up">
            <Notifications
              openNotification={this.openNotification}
              count={this.state.count}
              toggel={this.toggel}
            />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ settings, user }) => {
  // console.log(user, "userrrrrr");
  const { locale } = settings;
  return { locale };
};
export default connect(mapStateToProps, { userSignOut })(UserInfo);
